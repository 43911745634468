import './Banner.css'

function Banner1() {
  return ( 
    <>
     <div className="banner-div py-3">
      <div className="text-container text-center d-flex justify-content-center align-items-center w-100 flex-wrap">
        <h1 className='banner-text mx-3 small-caps'>request a call to get started</h1>
        <a href='tel:7632257396' className='banner-btn small-caps px-3 py-2'>request call</a>
      </div>
     </div>
    </>
  );
}

export default Banner1;