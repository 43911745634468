import AdmissionForm from 'Components/Content/AdmissionsForm/AdmissionForm';
import PageHeader from 'Components/Content/PageHeader/PageHeader';
import './Admissions.css';

function Admissions() {
  return ( 
    <>
      <PageHeader title='admissions center' />
      <div className='admissions-container container d-flex justify-content-center my-4 mb-5'>
        <div className='col-lg-8 col-xs-12'>
          <div className=''>
            <div>
              <h1 className='form-title text-center text-white my-2 pb-1 small-caps'>schedule a tour</h1>
            </div>
            <div>
              <AdmissionForm />                   
            </div>
          </div>
        </div>
        <div className='col-xs-12 mx-5'>
          <p className='info-text'>We're very excited that you've chosen us for your cares. Please provide some basic information on this form and one of our caring nurses will contact you shortly.</p>
        </div>
      </div>
    </>
  );
}

export default Admissions;