import './OurServices.css';
import data from '../../../data.json';

function OurServices() {

  const services = data.content.ourServices.services;

  return ( 
    <>
      <div id='emp-services' className='our-services d-flex justify-content-center align-items-center'>
        <div className='container text-container text-center'>
          <h1 className='title small-caps col-lg-6 mx-auto col-xs-12'>we offer a range of services to provide for your loved ones including:</h1>
          <ul className='services-group'>
            {services.map((service) => {
              return (
                <li className='list-item small-caps'>
                  <i className="fa-solid fa-check mx-2"></i>
                  <span className='service my-2'>{service}</span>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </>
  );
}

export default OurServices;