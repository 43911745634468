import './Footer.css';

function Footer() {
  return ( 
    <>
      <div className='footer-container d-flex justify-content-center'>
          <div className='container mx-3 d-flex flex-wrap align-items-center justify-content-center'>
            <div>
              <h2 className='section-title mb-0 small-caps'>get in touch</h2>
              <div className='number-container d-flex my-2 align-items-center'>
                <div className='d-flex align-items-center'><a className='contact-link number' href='tel:7632257396' target='_blank' rel="noreferrer"><i className='fa-solid fa-phone mx-2'></i>763-225-7396</a></div>
              </div>
              <div className='email-container my-2'>
                <div className='d-flex align-items-center'><a className='contact-link number' href='mailto:info@empiresystemshomecare.com' target='_blank' rel="noreferrer"><i className='fa-solid fa-envelope mx-2'></i>Info@empiresystemshomecare.com</a></div>
              </div>
              <div className='address-container my-2'>
                <div className='d-flex'><i className="fa-solid fa-map-location mx-2"></i><p className='number mb-0'>6248 Lakeland Avenue N suite 208 Brooklyn Park, Minnesota 55428</p></div>
              </div>
              <div className='social-container mt-4'>
                <h2 className='section-title mb-0 small-caps'>follow our socials</h2>
                <a className='contact-link' href='https://www.instagram.com/empiresystemshomecare/' target='_blank' rel="noreferrer">
                  <i className="fa-brands fa-instagram mx-2"></i>
                </a>
              </div>
              <div className='my-4'>© 2018 All Rights Reserved Empire Systems Home Care</div>      
            </div>
            <div className='my-4 mx-4'>
              <img className='logo my-auto' src={require('../../imgs/logo/empire_logo.webp')} alt='empire care logo' />
            </div>
          </div>
      </div>
    </>
  );
}

export default Footer;