import ContactForm from 'Components/ContactForm/ContactForm';
import PageHeader from 'Components/Content/PageHeader/PageHeader';
import './About.css'

function About() {

  const paragraph = [
    'Empire Systems Home Care is a local provider of home health care services in the Twin Cities area and surrounding counties. While we are a full fledge comprehensive home health care provider, our main focus is in Housing with Services (HWS). Our facilities feature a homelike environment that our clients can move into, while continuing to receive care and services from our loving staff.',
    'Empire Systems Home Care has been in existence since 2013 and since that time we have worked diligently to assemble a team of competent, compassionate and caring professionals to help us realize the company’s vision; of being a leader in the Home Care and Housing with Service industry both in Minnesota and in the Nation.',
    'Our care givers and staff here at are all highly-qualified, and have undergone all of the state required training and met all state licensing requirements. At Empire Cares, All of our staff and care givers understand that the needs and concerns of our clients and their families is our number one priority. We take pride in caring and caring is what we do best.',
    'If you’re searching for quality Home Health Care services and Assisted Living for you or your loved one, look no further! Empire Systems Home Care is your best choice. Our team of highly qualified and compassionate professionals and care givers are ready to provide you a level of service that is unsurpassed in our industry.',
    'We ensure that the privacy and security of our clients are guaranteed protected at all times and that their family members can rest easy, knowing that their loved ones are in good hands. Empire Systems Home Care (ESHC) is here for you no matter what your circumstances are.'
  ];

  return ( 
    <>
      <PageHeader title="about us" />
      <div className='container mt-4 about-js'>
        <img className='about-img' src={require('../../imgs/empcare_7.webp')} alt=""/>
        <div className='paragraphs-container'>
          {paragraph.map(paragraph => {
            return (
              <p className='paragraph'>{paragraph}</p>
              )
            } 
          )}
          <p className='adp-disclosure text-center small-caps'>anti-discrimination policy</p>
          <p className='paragraph'>Empire Systems Home Care promotes equality in provision of services and in our hiring and employment practices. In accordance with Title VI of the Civil Rights Act of 1964, we do not discriminate nor shall we deny services to any individual employee or client on the grounds of religion, national origin, gender, disability, sexual orientation, color, race, age, ancestry or citizenship.</p>          
        </div>
      </div>
      <div className='d-flex justify-content-center'>
        <div className='col-lg-6'>
          <ContactForm />
        </div>
      </div>
      
    </>
    );
}

export default About;