import './Navbar.css'
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import data from '../../data.json'

function NavbarComponent() {
  const navbar = data.content.navbar

  return (
    <Navbar key="lg" expand="lg" bg="light" className="w-100 py-1 sticky-top" collapseOnSelect>
      <Container>
        <Navbar.Brand href="/">
          <img alt="logo" className="logo h-100 py-2" src={require('../../imgs/logo/empire_logo.webp')} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-md`} />
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-md`}
          aria-labelledby={`offcanvasNavbarLabel-expand-md`}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-md`} className="">
              <h1 className='small-caps'>menu</h1>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 pe-3">
              {navbar.links.map((link, index) => {
                return (
                  <Nav.Link key={index} href={link.path} to={link.path} className="link-line text-dark mx-2 small-caps">{link.title}</Nav.Link>
                )
              })}
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>        
      </Container>
    </Navbar>
  );
}

export default NavbarComponent;
