import './Landing.css';
import data from '../../data.json'
import { Link } from 'react-router-dom';

function Landing() {
  const landing = data.content.landing;

  return ( 
    <div className="landing-container">
      <div className="hero-img">
      <div className="container w-100 h-100 col-lg-12 col-md-12 d-flex align-items-center justify-content-center">
          <div className="text-center text-container">
            <h2 className="hero-text mb-2 montserrat">At <span className='ec-span'>Empire Care</span>, caring is truly what we're all about.</h2>
            <p className="subtext montserrat text-white mb-2">{landing.subtext}</p>
            <Link to="/admissions"><button className="cta-button w-25 px-3 pb-2 py-1 small-caps">{landing.callToAction}</button></Link>
            <div className="carot-btn">
              <button>
                <i className="fa-solid fa-angle-down"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
}

export default Landing;