import ContactForm from 'Components/ContactForm/ContactForm';
import PageHeader from 'Components/Content/PageHeader/PageHeader.js';
import React from 'react';
import './Contact.css';

function Contact() {
  return ( 
    <>
      <PageHeader title='get in touch' />
      <div className='contact-page-container container'>
          <div className='d-flex justify-content-center'>
            <div className='col-lg-6'>
              <h1 className='small-caps text-center my-3'>empire systems home care</h1>
              <div className='address-container my-2 w-100 justify-content-center'>
                <div className='contact-info'><i className="fa-solid fa-map-location pt-2 mx-2"></i>6248 Lakeland Avenue N suite 208 Brooklyn Park, Minnesota 55428</div>
              </div>
              <div className='number-container d-flex my-2 align-items-center w-100 justify-content-center'>
                <div className='d-flex align-items-center contact-info'><a className='contact-link number' href='tel:7632257396' target='_blank' rel="noreferrer"><i className='fa-solid fa-phone mx-2'></i>763-225-7396</a></div>
              </div>
              <ContactForm /> 
            </div>
          </div>
      </div>
    </>
  );
}

export default Contact;