import PageHeader from "Components/Content/PageHeader/PageHeader";
import "./Careers.css";

function Careers() {
  const embeddedPdf = (function (d, v) {
    var s = d.getElementsByTagName(v)[0],
      p = s.parentNode,
      e = d.createElement(v);
    e.src =
      ("https:" === d.location.protocol ? "https://" : "http://") +
      "static.platoforms.com/assets/dist/formbuilder.js?v=15";
    p.insertBefore(e, s);
  })(document, "script");

  return (
    <>
      <PageHeader title="join the team" />
      <div className="careers-container container d-flex justify-content-center my-3 mt-4">
        <div>
          <div>
            <h1 className="hiring-title text-center">We Are Hiring</h1>
          </div>
          <div className="text-cont">
            <p>
              We are growing fast and looking for exciting talents to join our
              amazing and hard-working team.
            </p>
            <br></br>
            <p>Do you have what it takes?</p>
            <br></br>
            <div
              className="plato-form-widget"
              data-options="id:frhpc4b626v;width:100%;height:0;host:form.platoforms.com/"
            ></div>
            <div id="frhpc4b626v-loading-msg">
              <p>Loading the Form...</p>
            </div>{" "}
           { embeddedPdf }
          </div>
        </div>
      </div>
    </>
  );
}

export default Careers;
