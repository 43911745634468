import './Welcome.css';
import data from '../../../data';

function Content1() {
  const welcome = data.content.welcome;

  return ( 
    <>
      <div className="d-flex content-container justify-content-center col-xs-12 col-lg-12 container my-5">
        <div className="left-container col-xs-12 col-lg-6 text-center">
          <h1 className="left-header small-caps">{welcome.heading1}</h1>
          <p className='left-paragraph px-4 mb-1'>
            {welcome.paragraph1}
          </p><br/>
          <p className='left-paragraph px-4'>
            {welcome.paragraph2}
          </p>
        </div>
        <div className="right-container px-3 py-3 col-xs-12 col-lg-6 text-center">
          <img className="max-100" alt='patient' src={require(`../../../imgs/empcare_3.webp`)} />
        </div>
      </div>
    </>
  );
}

export default Content1;