import {
  Button,
  Col,
  FloatingLabel,
  Form,
  FormControl,
  InputGroup,
  Row,
} from "react-bootstrap";
import "./AdmissionForm.css";
import data from "../../../data.json";
import { useRef, useState } from "react";
// import html2pdf from "html2pdf.js";
import emailJs from '@emailjs/browser'

function AdmissionForm() {
  const admissionForm = data.content.admissionForm;
  const formRef = useRef();
  const [formData, setFormData] = useState({});
  // const userForm = html2pdf(formRef.current);

  const handleFormChange = (event) => {
    const data = formData;
    data[event.target.name] = event.target.value;
    setFormData(data);
  }

  

  const sendEmail = (e) => {
    e.preventDefault();
    console.log(formData)
    emailJs.sendForm('service_6nwq03y', 'template_p76ye19', formRef.current, 'Bhem2pmc5JnnUh5wa')
      .then((res) => {
        if (res.status === 200) {
          alert('message sent!')
        }
      }, (error) => console.log(error))
  }

  return (
    <>
      <Form ref={formRef} onSubmit={sendEmail}>
            <Row >
              {admissionForm.map((field, index) => {
                return (
                  <Col key={index} lg='6' xs='12' className="my-2">
                    <FloatingLabel
                      controlId={field.controlId}
                      label={field.labelName}
                      className="small-caps"
                    >
                      <FormControl
                        type={field.type}
                        placeholder={field.labelName}
                        name={field.labelName}
                        onChange={event => handleFormChange(event)}
                      />
                    </FloatingLabel>
                  </Col>
                );
              })}
            </Row>
        <Row>
          <Col lg={12} xs={12} className="my-2">
            <Form.Label className="small-caps">who is this admission for?</Form.Label>
            <InputGroup className="">
              <Form.Select className="small-caps" name='admissionFor' onChange={event => handleFormChange(event)}>
                <option>-select-</option>
                <option>self</option>
                <option>someone else</option>
              </Form.Select>
            </InputGroup>
          </Col>
          <Col lg={12} xs={12} className="my-2">
            <FloatingLabel
              controlId="admissionRelationship"
              label="relationship"
              className="small-caps"
            >
              <FormControl 
                type="text"
                placeholder="Relationship"
                name='relationship'
                onChange={event => handleFormChange(event)}
              />
            </FloatingLabel>
          </Col>
        </Row>
        <Row className="h-100">
          <Col className="my-2 h-100">
            <Form.Label className="small-caps">are you the responsible party?</Form.Label>
            <InputGroup className="h-100">
              <Form.Select className="small-caps" name='responsibleParty' onChange={event => handleFormChange(event)}>
                  <option>-select-</option>
                  <option>yes</option>
                  <option>no</option>
              </Form.Select>          
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col className="my-2 w-100">
            <FloatingLabel
              controlId="admissionDiagnosis"
              label="diagnosis"
              className="small-caps"
            >
              <FormControl 
                as="textarea"
                type="text"
                placeholder="diagnosis"
                name='diagnosis'
                onChange={event => handleFormChange(event)}
              />
            </FloatingLabel>
          </Col>
        </Row>
        <Row>
          <Col className="my-2">
            <Form.Label className="small-caps">are there any special requests or conditions we need to consier?</Form.Label>
            <InputGroup>
              <Form.Select className="small-caps" name='specialRequests' onChange={event => handleFormChange(event)}>
                <option>-select-</option>
                <option>yes</option>
                <option>no</option>
              </Form.Select>
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col className="my-2">
            <FloatingLabel
              controlId="admissionBriefInfo"
              label="if yes, plese provide a brief infrormaion"
              className="small-caps"
            >
              <FormControl 
                as="textarea"
                type="text"
                placeholder="if yes, plese provide a brief infrormaion"
                name='briefInfo'
                onChange={event => handleFormChange(event)}
              />
            </FloatingLabel>
          </Col>
        </Row>
        <Row>
          <Col className="my-2">
            <Form.Label className="small-caps">payment type</Form.Label>
            <InputGroup>
              <Form.Select className="small-caps" name='paymentType' onChange={event => handleFormChange(event)}>
                <option>-select-</option>
                <option>cadi waiver</option>
                <option>cac waiver</option>
                <option>elderly waiver</option>                  
              </Form.Select>
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col className="my-2">
            <Form.Label className="small-caps">preferred method of contact</Form.Label>
            <InputGroup>
              <Form.Select className="small-caps" name='methodOfContact' onChange={event => handleFormChange(event)}>
                <option>-select-</option>
                <option>phone</option>
                <option>email</option>
              </Form.Select>
            </InputGroup>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center mt-2">
          <Col>
            <Button type="submit" className="w-100 small-caps submit-button"><p className="mb-0">submit</p></Button>
          </Col>  
        </Row>
      </Form>
    </>
  );
}

export default AdmissionForm;
