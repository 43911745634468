import './Resources.css';
import data from '../../data.json'
import PageHeader from 'Components/Content/PageHeader/PageHeader';

function Resources
() {

  const resources = data.content.resources;

  console.log('resources: ', resources)

  return ( 
    <div className='resources-container'>
      <PageHeader title='resources' />
      <h2 className='text-center mt-3 small-caps'>resourceful links</h2>
      <div className=' d-flex flex-wrap justify-content-center align-items-center container'>
        {resources.map(resource => {
          return (
            <a className='img-link mx-3 py-4 col-lg-4 col-xs-12' href={resource.link} target='_blank' rel='noopener noreferrer'>
              <img 
                className='res-img'
                key={resource.id}
                src={require(`../../imgs/resources/${resource.img}`)}
                alt=''
              />
            </a>            
          )
        })}
      </div>
    </div>
  );
}

export default Resources
;