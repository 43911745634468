import Banner1 from "../../Components/Banner-1/Banner";
import ContactForm from "../../Components/ContactForm/ContactForm";
import GetStarted from "../../Components/Content/GetStarted/GetStarted";
import OurServices from "../../Components/Content/OurServices/OurServices";
import Content1 from "../../Components/Content/Welcome/Welcome";
import Landing from "../../Components/Landing/Landing";

function Home() {
  return ( 
    <>
      <Landing />
      <Content1 />
      <Banner1 />
      <OurServices />
      <GetStarted />
      <div className='col-lg-12 d-flex justify-content-center'>
        <div className="col-lg-6">
          <ContactForm />  
        </div>  
      </div>

    </>

  );
}

export default Home;