import './App.css';
import NavbarComponent from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';
import Home from './Pages/Home/Home';
import About from './Pages/About/About'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Resources from 'Pages/Resources/Resources';
import Admissions from 'Pages/Amissions/Admissions';
import Contact from 'Pages/Contact/Contact';
import Careers from 'Pages/Careers/Careers';

function App() {
  return (
    <>
      <NavbarComponent />
      <BrowserRouter>
        <Routes>
            <Route exact path='/' element={<Home/>} />
            <Route path='/about' element={<About />} />
            <Route path='/resources' element={<Resources />} />
            <Route path='/admissions' element={<Admissions />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/careers' element={<Careers />} />        
        </Routes>
      </BrowserRouter>
      <Footer />
    </>
  );
}

export default App;

// pre admission link and banner to say schedule a tour
// how did you hear about us

// add private pay to payment options
// add prefered date
// a brochure page