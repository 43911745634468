import './GetStarted.css'
import data from '../../../data.json';

function GetStarted() {

  const links = data.content.welcome.links;

  return ( 
    <div className="w-100 bg-image d-flex content-container justify-content-center">
      <div className="col-xs-12 col-lg-12 container text-center d-flex align-items-center justify-content-center">
        <div>
          <h1 className='mb-4 small-caps'>helpful resources</h1>
          <ul className="link-list-group my-4">
            {links.map(link => {
              return (
                <li className='d-flex link-container mx-3'>
                  <i className="fa-solid fa-chevron-right py-3 mx-2"></i>
                  <a href={link.path} className='quick-links small-caps '>{ link.title }</a>
                </li>
              )
            })}                 
          </ul>
       
        </div>
      </div>
    </div>
  );
}

export default GetStarted;