import './PageHeader.css';

function PageHeader(props) {
  return ( 
    <>
      <div className='header-container d-flex align-items-center'>
        <div className='container'>
          <h1 className='page-header-title small-caps py-3'>{ props.title }</h1>
        </div>
      </div>
    </>
  );
}

export default PageHeader;